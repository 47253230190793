'use client';

import React, { useEffect } from 'react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useParams } from 'next/navigation';

import type { CommunityMember } from '@zealy/queries';
import { Button, IconButton, PopoverTrigger, TooltipProvider } from '@zealy/design-system';
import { ArrowCornerCcwLtLine, CalendarEventLine, EditLine, RotateCcwLine } from '@zealy/icons';
import { questKeys, useAuthenticatedUser, useResetClaims } from '@zealy/queries';
import { roleIsAtLeast } from '@zealy/utils';

import { queryClient } from '#app/QueryProvider';
import { LaunchCommunity } from '#components/Onboarding/LaunchCommunity';
import { toast } from '#components/Toaster';
import { useCommunityV2 } from '#hooks/useCommunityV2';
import { revalidateTags } from '#utils/serverAction';

export const AdminPreviewBanner = ({ subdomain }: { subdomain: string }) => {
  const user = useAuthenticatedUser<CommunityMember>();
  const community = useCommunityV2();
  const resetClaims = useResetClaims(user.data?.id, subdomain);
  const { questId } = useParams<{ questId?: string }>();
  const t = useTranslations('questboard.preview-banner');
  const tLaunch = useTranslations('onboarding.steps.launch');

  const baseHref = `/cw/${subdomain}/questboard/admin`;

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });
  }, [subdomain]);

  const launchDateNotSet = community.data && !community.data.launchDate;

  if (!roleIsAtLeast(user.data?.role, 'editor')) return null;

  const onResetClaims = () => {
    resetClaims.mutate(questId, {
      onSuccess: claimIds => {
        revalidateTags(['questboard']);
        revalidateTags([`${subdomain}:sprints`]);
        toast.success(t('reset-claims.success', { count: claimIds.length }));
      },
      onError: e => {
        toast.error(t('reset-claims.error'));
      },
    });
  };

  return (
    <div className="py-200 px-300 rounded-md bg-secondary flex flex-col md:flex-row gap-100 md:gap-500 items-center flex-shrink-0">
      <div>
        <p className="body-paragraph-md-bold text-primary">{t('title')}</p>
        <p className="body-paragraph-md text-secondary">
          {t('description')} {launchDateNotSet ? tLaunch('description-full') : ''}
        </p>
      </div>
      <div className="flex gap-150 ml-auto">
        {questId && (
          <TooltipProvider>
            <IconButton
              icon={<EditLine />}
              variant="muted"
              size="sm"
              as={Link}
              href={`${baseHref}/${questId}`}
              onlyIcon
              tooltip={t('edit')}
            />
          </TooltipProvider>
        )}
        <TooltipProvider>
          <IconButton
            icon={<RotateCcwLine />}
            variant="muted"
            size="sm"
            onClick={onResetClaims}
            onlyIcon
            tooltip={t('reset-claims.title')}
          />
        </TooltipProvider>
        <Button
          leftIcon={<ArrowCornerCcwLtLine />}
          variant="muted"
          size="sm"
          as={Link}
          href={baseHref}
        >
          {t('back')}
        </Button>
        {launchDateNotSet && user.data?.role === 'admin' && (
          <LaunchCommunity launchDate={null}>
            <PopoverTrigger asChild>
              <Button color="cta" leftIcon={<CalendarEventLine />} size="sm">
                {tLaunch('label')}
              </Button>
            </PopoverTrigger>
          </LaunchCommunity>
        )}
      </div>
    </div>
  );
};
